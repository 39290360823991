import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"

export default class extends Controller {
  connect() {
    let form = this.element
    form.addEventListener('submit', function (event) {
      Modal.getInstance($(form).parents('.modal').get(0)).hide()
      form.requestSubmit()
    })
  }

  openonedit (event) {
    let modalId = $(this.element).data('modal-id')
    let modalEl = $(`#${modalId}`).get(0)
    let myModal = new Modal(modalEl)
    myModal.show()
  }
}