// locales
import datepickerIt from './datepicker.it'
$.fn.datepicker.dates.it = datepickerIt

// defaults
$.extend($.fn.datepicker.defaults, {
  format: 'dd/mm/yyyy',
  weekStart: 1,
  language: !localStorage.getItem('locale') ? 'it' : localStorage.getItem('locale'),
  orientation: 'bottom',
  todayHighlight: true,
  clearBtn: true
});

// init
$(function(){
  $('.datepicker').datepicker();
  $('.datepicker-inline').datepicker();
  $('.datepicker-autoclose').datepicker({
    autoclose: true
  });
  $('.datepicker-multiple-date').datepicker({
    multidate: true,
    multidateSeparator: ","
  });
  $('.date-range').datepicker({
    toggleActive: true
  });
});