(function ($) {
  'use strict';

  function initLocale () {
    // controllo se ho già impostato la lingua ed in caso affermativo non faccio nulla
    if (!localStorage.getItem('locale')) {
      // richiamo la lingua dell'utente corrente
      $.ajax({
        method: 'GET',
        url: '/users/locale',
        async: false,
        success: function (data) {
          if (data.locale == undefined) {
            // se non ho lingua e ho il messaggio di 'no_user', non devo fare nulla perchè sono fuori dalla sessione (TODO: lingua di default)
            if (data.message == 'no_user') { }
          } else {
            // se ho la lingua la imposto con l'it o con quella salvata per l'utente corrente
            let lang = 'it'; // default
            if (data.locale != null) { lang = data.locale; }
            localStorage.setItem('locale', lang);
          }
        }
      });
    }
  }

  initLocale();
})(jQuery)