import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"

export default class extends Controller {
  connect() {
    let form = this.element
    form.addEventListener('submit', function (event) {
      if (!form.checkValidity()) {
        event.preventDefault()
        event.stopPropagation()
      } else {
        Modal.getInstance($(form).parents('.modal').get(0)).hide()
        form.requestSubmit()
      }
      form.classList.add('was-validated')
    }, false)
  }

  openonedit (event) {
    let modalId = $(this.element).data('modal-id')
    let modalEl = $(`#${modalId}`).get(0)
    let myModal = new Modal(modalEl)
    myModal.show()
  }
}