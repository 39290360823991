// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import Rails from "@rails/ujs"
window.Rails = Rails;
Rails.start();

/** JQUERY **/
import jquery from 'jquery';
window.jQuery = jquery;
window.$ = jquery;

// Swiper JS
import Swiper from "swiper/bundle";
window.Swiper = Swiper;

/** ALPINE.JS **/
import Alpine from 'alpinejs'
window.Alpine = Alpine;
Alpine.start();

/** BOOTSTRAP **/
import * as bootstrap from "bootstrap";
window.bootstrap = bootstrap;
let popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))  
let popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
  return new bootstrap.Popover(popoverTriggerEl)
})
var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
  return new bootstrap.Tooltip(tooltipTriggerEl)
})
import { Modal } from "bootstrap";
window.Modal = Modal;

/** PLUGINS **/
// datatable https://datatables.net/download/
require('datatables.net-bs4')();
require('datatables.net-autofill-bs4')();
require('datatables.net-buttons-bs4')();
require('datatables.net-buttons/js/buttons.colVis.js')();
require('datatables.net-responsive-bs4')();
require('datatables.net-select-bs4')();
// datepicker
require('bootstrap-datepicker');
// inline editable
require('./bootstrap-editable');
// sweet alert 2
import Swal from 'sweetalert2'
window.Swal = Swal
// jquery steps
require('./jquery.steps.min');
// metismenu (sidebar)
require('metismenu');
// jquery autocomplete
require('./jquery.autocomplete.min');
// jquery repeater
require('jquery.repeater');
// inputmask
require('inputmask');
// chart.js
import Chart from 'chart.js/auto'
window.Chart = Chart
// ckeditor5
require('./ckeditor5_37_1_0/translations/en');
import ClassicEditor from './ckeditor5_37_1_0/ckeditor.js';
window.ClassicEditor = ClassicEditor;
// lodash
import _ from 'lodash';
window._ = _;
// toastr
// require('toastr');
import toastr from 'toastr/toastr';
window.toastr = toastr;


/** CUSTOM **/
// require('./ol');
// require('./olAPIProcne');
require('./fontawesome');
require('./locale');
require('./plugins');
require('./main');