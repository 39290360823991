// caricamento lingue
const datatableIt = require('./datatable.it.json');
const datatableEn = require('./datatable.en.json');

// inizializzazione
$.extend(true, $.fn.dataTable.defaults, {
  retrieve: true,
  processing: true,
  serverSide: true,
  language: localStorage.getItem('locale') == 'it' ? datatableIt : datatableEn,
  lengthMenu: [ 10, 20, 40, 80 ],
  pagingType: 'first_last_numbers', // numbers | simple | simple_numbers | full | full_numbers | first_last_numbers
  autoWidth: false,
  dom: //'ltipr',
    't' +
    '<"bottom"' +
      '<"row mt-2 mx-0"' +
        '<"col-sm-12 col-xl-3"i>' +
        '<"col-sm-12 col-xl-9 d-flex align-items-baseline justify-content-end"' +
          '<"me-3"l>' +
        'p>' +
      '>' +
    'r>',
  stateSaveParams: function(settings, data) {
    var dataCustom = data.custom;
    var customFilters = (dataCustom) ? JSON.parse(data.custom) : {};
    $('.datatable-filter').each(function (index) { customFilters[this.id] = this.value; });
    $('.datatable-filter-datetime').each(function (index) { customFilters[this.id] = this.value; });
    data.custom = customFilters
  },
  stateLoadParams: function(settings, data) {
    var dataCustom = data.custom;
    if (dataCustom) {
      $('.datatable-filter').each(function (index) { this.value = dataCustom[this.id]; });
      $('.datatable-filter-datetime').each(function (index) { if (dataCustom[this.id] !== undefined ) { this.value = dataCustom[this.id]; } else { this.value = ''; } });
    }
  },
});
