// Huge: FontAwesome completo, non ha bisogno di altri import ma è sconsigliato per le dimensioni
//         yarn add @fortawesome/fontawesome-pro@next
//
// import '@fortawesome/fontawesome-pro/js/all';


// Medium: import delle singole varianti, quindi dimensioni ridotte rispetto alla versione completa,
//         ma comunque abbastanza importanti in quanto ogni variante ha svariate centinaia di icone;
//         l'ultimo import (js/fontawesome) è necessario per il buon funzionamento
//           yarn add @fortawesome/fontawesome-pro@next
//
// import '@fortawesome/fontawesome-pro/js/solid';
// import '@fortawesome/fontawesome-pro/js/regular';
// import '@fortawesome/fontawesome-pro/js/brands';
// import '@fortawesome/fontawesome-pro/js/light';
// import '@fortawesome/fontawesome-pro/js/duotone';
// import '@fortawesome/fontawesome-pro/js/fontawesome';


// Small: include solo le icone utilizzate, quindi lo spazio è ridotto al minimo
//        eseguire l'apposito task per aggiornare la lista degli import tra i commenti BEGIN ed END sottostanti,
//        da utilizzare ogni volta che si aggiunge o rimuove un'icona:
//          rails fontawesome:repack
//        Per utilizzarlo sono necessari i seguenti pacchetti:
//          yarn add @fortawesome/pro-{solid,regular,light,duotone}-svg-icons@next @fortawesome/fontawesome-svg-core@next
//
import { library, dom } from '@fortawesome/fontawesome-svg-core';
// BEGIN fontawesome:repack - Attenzione: il contenuto tra questi commenti viene sovrascritto dall'esecuzione del task
import {
  faAt as farAt,
  faBars as farBars,
  faCircleQuestion as farCircleQuestion,
  faThumbsUp as farThumbsUp,
  faXmark as farXmark,
  faXmarkLarge as farXmarkLarge,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faCheck as fasCheck,
  faChevronLeft as fasChevronLeft,
  faChevronRight as fasChevronRight,
  faClipboardCheck as fasClipboardCheck,
  faEdit as fasEdit,
  faGhost as fasGhost,
  faList as fasList,
  faLock as fasLock,
  faPersonThroughWindow as fasPersonThroughWindow,
  faSave as fasSave,
  faSignInAlt as fasSignInAlt,
  faSpinner as fasSpinner,
  faTimes as fasTimes,
  faTrashAlt as fasTrashAlt,
  faUndo as fasUndo,
  faUndoAlt as fasUndoAlt,
  faUserPlus as fasUserPlus,
} from '@fortawesome/pro-solid-svg-icons';
import {
  faFacebook as fabFacebook,
  faHtml5 as fabHtml5,
  faInstagram as fabInstagram,
  faLinkedin as fabLinkedin,
  faTwitter as fabTwitter,
  faVimeo as fabVimeo,
  faYoutube as fabYoutube,
} from '@fortawesome/free-brands-svg-icons';
import {
  faArrowRightToLine as fadArrowRightToLine,
  faBackward as fadBackward,
  faBallotCheck as fadBallotCheck,
  faBinaryCircleCheck as fadBinaryCircleCheck,
  faCalendarCircleUser as fadCalendarCircleUser,
  faCloudArrowDown as fadCloudArrowDown,
  faEllipsis as fadEllipsis,
  faEnvelopeCircleCheck as fadEnvelopeCircleCheck,
  faForward as fadForward,
  faHouse as fadHouse,
  faIdCardClip as fadIdCardClip,
  faIndustryWindows as fadIndustryWindows,
  faKey as fadKey,
  faLockKeyhole as fadLockKeyhole,
  faMegaphone as fadMegaphone,
  faPaperPlane as fadPaperPlane,
  faPeopleGroup as fadPeopleGroup,
  faPersonThroughWindow as fadPersonThroughWindow,
  faPlaneLock as fadPlaneLock,
  faQrcode as fadQrcode,
  faRightFromBracket as fadRightFromBracket,
  faSignInAlt as fadSignInAlt,
  faSpinner as fadSpinner,
  faTableTennisPaddleBall as fadTableTennisPaddleBall,
  faTag as fadTag,
  faUnlock as fadUnlock,
  faUser as fadUser,
  faUserMinus as fadUserMinus,
  faUserPen as fadUserPen,
  faUserPlus as fadUserPlus,
  faUsersGear as fadUsersGear,
} from '@fortawesome/pro-duotone-svg-icons';
library.add(fabFacebook, fabHtml5, fabInstagram, fabLinkedin, fabTwitter, fabVimeo, fabYoutube, fadArrowRightToLine, fadBackward, fadBallotCheck, fadBinaryCircleCheck, fadCalendarCircleUser, fadCloudArrowDown, fadEllipsis, fadEnvelopeCircleCheck, fadForward, fadHouse, fadIdCardClip, fadIndustryWindows, fadKey, fadLockKeyhole, fadMegaphone, fadPaperPlane, fadPeopleGroup, fadPersonThroughWindow, fadPlaneLock, fadQrcode, fadRightFromBracket, fadSignInAlt, fadSpinner, fadTableTennisPaddleBall, fadTag, fadUnlock, fadUser, fadUserMinus, fadUserPen, fadUserPlus, fadUsersGear, farAt, farBars, farCircleQuestion, farThumbsUp, farXmark, farXmarkLarge, fasCheck, fasChevronLeft, fasChevronRight, fasClipboardCheck, fasEdit, fasGhost, fasList, fasLock, fasPersonThroughWindow, fasSave, fasSignInAlt, fasSpinner, fasTimes, fasTrashAlt, fasUndo, fasUndoAlt, fasUserPlus);
// END fontawesome:repack - Attenzione: il contenuto tra questi commenti viene sovrascritto dall'esecuzione del task
dom.watch();

// Fix fullcalendar bug: https://github.com/fullcalendar/fullcalendar/issues/5544#issuecomment-658406050
import { config } from '@fortawesome/fontawesome-svg-core';
config.autoReplaceSvg = 'nest';