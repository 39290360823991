// comportamento dopo il click sulla conferma
const confirmed = (element, result, action, translations, url, afterConfirmRedirectTo, afterConfirmCallback) => {
  if (result.value) {
    if (action == 'delete') {
      $.ajax({
        method: 'DELETE',
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
        url: url + '?format=json',
        success: function (data) {
          if (data.deleted_at) {
            console.log(afterConfirmRedirectTo)
            if (afterConfirmRedirectTo == undefined || afterConfirmRedirectTo == '' || afterConfirmRedirectTo == null) { $('.dataTable').DataTable().ajax.reload() }

            if (afterConfirmCallback) {
              console.log("call: ", afterConfirmCallback);
              var callFunc = new Function(afterConfirmCallback + '()')
              callFunc()
            }

            Swal.fire({
              customClass: {
                confirmButton: 'btn btn-secondary'
              },
              title: translations.success,
              icon: 'success',
              confirmButtonText: '<i class="far fa-thumbs-up"></i> ' + translations.button_close,
              confirmButtonAriaLabel: translations.button_close,
              buttonsStyling: false
            }).then(function (result) {
              if (afterConfirmRedirectTo) { window.location.href = afterConfirmRedirectTo }
            })
          } else {
            location.reload()
          }
        }
      });
    } else if (action == 'custom_post') {
      $.ajax({
        method: 'POST',
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
        url: url + '?format=json',
        success: function (data) {
          if (data.response) {
            console.log(afterConfirmRedirectTo)
            if (afterConfirmRedirectTo == undefined || afterConfirmRedirectTo == '' || afterConfirmRedirectTo == null) { $('.dataTable').DataTable().ajax.reload() }

            if (afterConfirmCallback) {
              console.log("call: ", afterConfirmCallback);
              var callFunc = new Function(afterConfirmCallback + '()')
              callFunc()
            }
            var afterId = data.response.after_id;
            var afterAction = data.response.after_action;
            Swal.fire({
              customClass: {
                confirmButton: 'btn btn-secondary'
              },
              title: translations.success,
              icon: 'success',
              confirmButtonText: '<i class="far fa-thumbs-up"></i> ' + translations.button_close,
              confirmButtonAriaLabel: translations.button_close,
              buttonsStyling: false
            }).then(function (result) {
              if (afterConfirmRedirectTo) {
                var windowLocationHref = afterConfirmRedirectTo
                if (afterId) {
                  windowLocationHref = afterConfirmRedirectTo + '/' + afterId
                  if (afterAction) { windowLocationHref += '/' + afterAction; }
                }
                window.location.href = windowLocationHref
              }
            })
          } else {
            location.reload()
          }
        }
      });
    } else {
      Swal.fire({
        customClass: {
          confirmButton: 'btn btn-secondary'
        },
        title: translations.success,
        icon: 'success',
        confirmButtonText: '<i class="far fa-thumbs-up"></i> ' + translations.button_close,
        confirmButtonAriaLabel: translations.button_close,
        buttonsStyling: false
      })
    }
  } else {
    Swal.fire({
      customClass: {
        confirmButton: 'btn btn-secondary'
      },
      title: translations.canceled,
      icon: 'info',
      confirmButtonText: '<i class="fas fa-ghost"></i> ' + translations.button_close,
      confirmButtonAriaLabel: translations.button_close,
      buttonsStyling: false
    });
  }
}

// visualizzazione della finestra di dialogo per la conferma
const showConfirmationDialog = (element) => {
  const message = element.getAttribute('data-confirm-swal');
  const context = element.getAttribute('data-context');
  const text = element.getAttribute('data-text');
  const url = element.getAttribute('data-target'); // "/roles/1"
  const action = element.getAttribute('data-action'); // delete, custom_post
  const icon = element.getAttribute('data-icon-swal'); // success, error, warning, info, question
  const customConfirmButtonClass = element.getAttribute('data-confirm-button-class'); // btn btn-danger ms-1, btn btn-success ms-1, ...
  const customCancelButtonClass = element.getAttribute('data-cancel-button-class'); // btn btn-secondary me-1, ...
  // const customConfirmButtonColor = element.getAttribute('data-confirm-button-color'); // 
  const afterConfirmRedirectTo = element.getAttribute('data-after-confirm-redirect-to');
  const afterConfirmCallback = element.getAttribute('data-after-confirm-callback');

  $.ajax({
    method: 'GET',
    url: '/users/swal_translations?format=json&context=' + context,
    success: function (data) {
      if (data.length != 0) {
        let translations = null
        let confirmButtonIcon = ''
        let cancelButtonIcon = '' // '<i class="fas fa-times"></i> '
        let confirmButtonColor = '#2669cf'
        let confirmButtonClass = 'btn btn-success ms-1'
        let cancelButtonClass = 'btn btn-secondary me-1'
        if (context == 'confirm_delete' && action == 'delete') {
          translations = data
          //confirmButtonIcon = '<i class="fas fa-trash-alt"></i> '
          confirmButtonColor = '#dc3545'
          confirmButtonClass = customConfirmButtonClass || 'btn btn-danger ms-1'
          cancelButtonClass = customCancelButtonClass || 'btn btn-secondary me-1'
        } else {
          translations = data
          //confirmButtonIcon = '<i class="fas fa-check"></i> '
          confirmButtonClass = customConfirmButtonClass || 'btn btn-success ms-1'
          cancelButtonClass = customCancelButtonClass || 'btn btn-secondary me-1'
        }
        // etichette generiche
        if (translations == undefined) {
          translations = {
            button_ok: 'Sì, conferma!',
            button_cancel: 'No, annulla',
            success: 'Azione eseguita',
            canceled: 'Azione annullata',
            button_close: 'Chiudi'
          }
        }

        Swal.fire({
          customClass: {
            confirmButton: confirmButtonClass,
            cancelButton: cancelButtonClass
          },
          title: message || translations.title,
          text: text || translations.text,
          icon: icon || 'question',
          showCancelButton: true,
          confirmButtonText: confirmButtonIcon + ' ' + translations.button_ok,
          confirmButtonColor: confirmButtonColor,
          confirmButtonAriaLabel: translations.button_ok,
          cancelButtonText: cancelButtonIcon + ' ' + translations.button_cancel,
          cancelButtonAriaLabel: translations.button_cancel,
          reverseButtons: true,
          buttonsStyling: false
        }).then(result => confirmed(element, result, action, translations, url, afterConfirmRedirectTo, afterConfirmCallback))
      }
    }
  })
}

const allowAction = (element) => {
  if (element.getAttribute('data-confirm-swal') === null) {
    return true;
  }
  showConfirmationDialog(element);
  return false;
}

function handleConfirm(element) {
  if (!allowAction(this)) {
    Rails.stopEverything(element);
  }
}

Rails.delegate(document, 'a[data-confirm-swal]', 'click', handleConfirm)
