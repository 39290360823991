ClassicEditor.defaultConfig = {
  toolbar: [
    'heading',
    '|',
    'bold',
    'italic',
    'underline',
    'code',
    'fontColor',
    '|',
    'link',
    'bulletedList',
    'numberedList',
    '|',
    'alignment',
    'outdent',
    'indent',
    '|',
    'blockQuote',
    // 'insertTable',
    // 'mediaEmbed',
    'undo',
    'redo',
    '|',
    'sourceEditing'
  ]
};