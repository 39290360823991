/*
Template Name: Amezia - Admin & Dashboard Template
Author: Themesbrand
Version: 2.0.0
Website: https://themesbrand.com/
Contact: themesbrand@gmail.com
File: Main Js File
*/


(function ($) {

  'use strict';

  // window.setTimeout(function() {
  //   $(".alert").fadeTo(500, 0).slideUp(500, function(){
  //       $(this).remove(); 
  //   });
  // }, 2000);

  function initMetisMenu() {
    //metis menu
    $("#side-menu").metisMenu();
  }

  function initLeftMenuCollapse() {
    $('#vertical-menu-btn').on('click', function (event) {
      event.preventDefault();
      $('body').toggleClass('sidebar-enable');
      if ($(window).width() >= 992) {
        $('body').toggleClass('vertical-collpsed');
      } else {
        $('body').removeClass('vertical-collpsed');
      }
    });
  }

  function initMenuItemScroll() {
    // focus active menu in left sidebar
    $(document).ready(function () {
      if ($("#sidebar-menu").length > 0 && $("#sidebar-menu .mm-active .active").length > 0) {
        var activeMenu = $("#sidebar-menu .mm-active .active").offset().top;
        if (activeMenu > 300) {
          activeMenu = activeMenu - 300;
          $(".vertical-menu .simplebar-content-wrapper").animate({
            scrollTop: activeMenu
          }, "slow");
        }
      }
    });
  }

  function initFullScreen() {
    $('[data-toggle="fullscreen"]').on("click", function (e) {
      e.preventDefault();
      $('body').toggleClass('fullscreen-enable');
      if (!document.fullscreenElement && /* alternative standard method */ !document.mozFullScreenElement && !document.webkitFullscreenElement) { // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    });
    document.addEventListener('fullscreenchange', exitHandler);
    document.addEventListener("webkitfullscreenchange", exitHandler);
    document.addEventListener("mozfullscreenchange", exitHandler);

    function exitHandler() {
      if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
        console.log('pressed');
        $('body').removeClass('fullscreen-enable');
      }
    }
  }

  function initRightSidebar() {
    // right side-bar toggle
    $('.right-bar-toggle').on('click', function (e) {
      $('body').toggleClass('right-bar-enabled');
    });

    $(document).on('click', 'body', function (e) {
      if ($(e.target).closest('.right-bar-toggle, .right-bar').length > 0) {
        return;
      }

      $('body').removeClass('right-bar-enabled');
      return;
    });
  }

  // usato per navbar orizzontale
  // function initDropdownMenu() {
  //   if (document.getElementById("topnav-menu-content")) {
  //     var elements = document.getElementById("topnav-menu-content").getElementsByTagName("a");
  //     for (var i = 0, len = elements.length; i < len; i++) {
  //       elements[i].onclick = function (elem) {
  //         if (elem.target.getAttribute("href") === "#") {
  //           elem.target.parentElement.classList.toggle("active");
  //           elem.target.nextElementSibling.classList.toggle("show");
  //         }
  //       }
  //     }
  //     window.addEventListener("resize", updateMenu);
  //   }
  // }

  function initComponents() {
    /* CUSTOM INITS */
    //** LOCALE **//
    $('#logout').on('click', function(){
      localStorage.removeItem('locale');
    });
    //** TOGGLE PWD **//
    window.initPwdToggles = function () {
      $('.togglePassword').on('click', function(){
        $(this).toggleClass('mdi-eye mdi-eye-off');
        let input = $($(this).data('toggle'));
        if (input.attr('type') == 'password'){
          input.attr('type', 'text');
        }
        else{
          input.attr('type', 'password');
        }
      });
    }
    //** NUMBER INPUT CON VIRGOLA **//
    $('input.number-input').on('keypress', function(e){
      let charCode = (e.which) ? e.which : e.keyCode;
      let has_comma = $(this).val().indexOf(',') > 0; // only one comma allowed
      if(charCode == 44 && has_comma){
        e.preventDefault();
      }else if (charCode > 31 && (charCode != 44 && charCode != 45 && (charCode < 48 || charCode > 57))){
        e.preventDefault();
      }
    });
  }

  function initInputmask () {
    Inputmask.extendAliases({
      euro: {
        prefix: "€ ",
        groupSeparator: ".",
        radixPoint: ",",
        alias: "numeric",
        placeholder: "0",
        autoGroup: true,
        digits: 2,
        digitsOptional: false,
        clearMaskOnLostFocus: false,
        autoUnmask: true,
        unmaskAsNumber: true
      }
    });
  }

  function init() {
    initMetisMenu();
    initLeftMenuCollapse();
    initMenuItemScroll();
    initFullScreen();
    initRightSidebar();
    // initDropdownMenu();
    initComponents();
    initPwdToggles();
    initInputmask();
  }

  init();

  window.emailVerification = function (email, regex = null) {
    if (regex == null) {
      regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    }
    return regex.test(email);
  }

})(jQuery)